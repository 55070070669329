{
  "name": "ui",
  "version": "4.4.0",
  "description": "",
  "scripts": {
    "start": "esbuild src/app.js --target=es6 --bundle --sourcemap --outfile=static/index.js --loader:.js=jsx --loader:.png=dataurl --loader:.jpeg=dataurl --format=iife --watch --serve --servedir=./static",
    "build": "esbuild src/app.js --target=es6 --bundle --sourcemap --outfile=static/index.js --loader:.js=jsx --loader:.png=dataurl --loader:.jpeg=dataurl --format=iife --define:process.env.NODE_ENV=\\\"production\\\" --define:window.BUILD_COMMIT_HASH=\\\"$(git describe --always --dirty)\\\"",
    "typecheck": "tsc --noEmit"
  },
  "keywords": [],
  "author": "",
  "license": "ISC",
  "prettier": {
    "singleQuote": true
  },
  "dependencies": {
    "@atproto/api": "^0.10.4",
    "@emotion/react": "^11.11.1",
    "@emotion/styled": "^11.11.0",
    "@mui/icons-material": "^5.14.16",
    "@mui/material": "^5.14.20",
    "@mui/styles": "^5.14.20",
    "@tanstack/react-query": "^5.61.4",
    "@yornaath/batshit": "^0.10.1",
    "ag-grid-community": "^31.0.2",
    "ag-grid-react": "^31.0.3",
    "esbuild": "^0.20.0",
    "fuse.js": "^7.0.0",
    "iso-web": "^1.0.6",
    "react": "^18.2.0",
    "react-dom": "^18.2.0",
    "react-router-dom": "^6.18.0"
  },
  "devDependencies": {
    "typescript": "^5"
  }
}
